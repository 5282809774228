document.addEventListener('DOMContentLoaded', function () {
	let caseSwiper = new Swiper("#case-swiper", {
		slidesPerView: 1,
		autoHeight: true,
		spaceBetween: 0,
		allowTouchMove: false,
		// centeredSlides: true,
		// paginationClickable: true,
		// loop: true,
		// slideToClickedSlide: true,

		pagination: {
			el: "#case-swiper-pagination",
			clickable: true,
		},

		navigation: {
			nextEl: '#case-swiper-button-next',
			prevEl: '#case-swiper-button-prev',
		},

		// autoplay: {
		// 	delay: 5000,
		// 	speed: 800,
		// 	disableOnInteraction: false,
		// },
	});
})

$('.js-beforeAfter').beforeAfter({
	// is draggable/swipeable
	movable: true,

	// click image to move the slider
	clickMove: true,

	// always show the slider
	alwaysShow: true,

	// intial position of the slider
	position: 50,

	// opacity between 0 and 1
	opacity: 0.4,
	activeOpacity: 1,
	hoverOpacity: 0.8,

	// slider colors
	separatorColor: '#ffffff',
	bulletColor: '#ffffff',
	arrowColor: '#333333',

});