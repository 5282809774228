// let aboutImagesSwiper = new Swiper('#about-images-swiper', {
// 	loop: true,
// 	// slidesPerView: 'auto',
// 	slidesPerView: 1,
// 	// slidesPerGroup: 5,
// 	centeredSlides: true,
// 	spaceBetween: 20,
// 	// autoHeight: true,

// 	pagination: {
// 		el: "#about-images-swiper-pagination",
// 		clickable: true,
// 	},
// 	navigation: {
// 		nextEl: '#about-images-swiper-button-next',
// 		prevEl: '#about-images-swiper-button-prev',
// 	},

// 	breakpoints: {
// 		// when window width is >= 320px
// 		320: {
// 			slidesPerView: 1,
// 			slidesPerGroup: 1,
// 			spaceBetween: 20
// 		},
// 		// when window width is >= 480px
// 		480: {
// 			slidesPerView: 1,
// 			slidesPerGroup: 1,
// 			spaceBetween: 30
// 		},
// 		// when window width is >= 640px
// 		640: {
// 			slidesPerView: 5,
// 			slidesPerGroup: 1,
// 			spaceBetween: 40
// 		}
// 	}
// })

let aboutTextSwiper = new Swiper("#about-text-swiper", {
	slidesPerView: 1,
	autoHeight: true,
	spaceBetween: 0,
	centeredSlides: true,

	// pagination: {
	// 	el: "#about-text-swiper-pagination",
	// 	clickable: true,
	// },

	navigation: {
		nextEl: '#about-text-swiper-button-next',
		prevEl: '#about-text-swiper-button-prev',
	},

	// autoplay: {
	// 	delay: 5000,
	// 	speed: 800,
	// 	disableOnInteraction: false,
	// },

	thumbs: {
		// swiper: aboutImagesSwiper,
	},
});