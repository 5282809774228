document.addEventListener('DOMContentLoaded', function () {
	let sertificatesSwiper = new Swiper("#specialist-certificate-swiper", {
		slidesPerView: "auto",
		spaceBetween: 17,

		pagination: {
			el: "#specialist-certificate-swiper-pagination",
			clickable: true,
		},

		navigation: {
			nextEl: '#specialist-certificate-swiper-button-next',
			prevEl: '#specialist-certificate-swiper-button-prev',
		},

		// autoplay: {
		// 	delay: 5000,
		// 	speed: 800,
		// 	disableOnInteraction: false,

		// },

		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 2,
				slidesPerGroup: 2,
				spaceBetween: 10
			},
			// when window width is >= 480px
			480: {
				slidesPerView: "auto",
				spaceBetween: 17,
			},
		}
	});
})