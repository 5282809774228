document.addEventListener("DOMContentLoaded", function (event) {

	if (window.innerWidth >= 430) {

		// Масштабирование страницы на десктопах
		PageScaleJs(document.getElementById("scale-element"), {
			width: 1550, //original width of you site-layer (like original width of image)
			height: 1000, // original heigth of you site-layer (like original height of image)

			// from: 1920, // start width interval (by default equal width parametr)
			to: 500, // end width interval (by default equal 0)

			onChangeScale: function (scale) {
				//receive value of scale factor
			}
		});
	}
});
