document.addEventListener('DOMContentLoaded', function () {

	// Опции
	let options = {
		callbackButtonClass: '.js-button-callback', // Class кнопки обратного звонка
		appointmentButtonClass: '.js-button-appointment', // Class кнопки записи на прием
		// defaultCallbackFormID: '#modal-callback', // ID модальной формы
		defaultCallbackFormID: '#dialog-calcproject-form', // ID модальной формы "Рассчитать проект" - ДЕФОЛТНАЯ
		orderFormID: '#modal-form-order', // Оставить заявку

	}

	let callbackButtons = document.querySelectorAll(options.callbackButtonClass);
	let appointmentButtons = document.querySelectorAll(options.appointmentButtonClass);

	function closeFeedbackForm() {
		Fancybox.close([{ src: "#dialog-content", type: "inline" }])
	}

	// Показываем дефолтное окно "Обратный звонок"
	function showDefaultCallbackForm() {
		Fancybox.show([{
			src: options.orderFormID,
			type: "inline"
		}])
		// Fancybox.show([{ src: "https://razvitie-personala.github.io/Gazoopasnie_raboti_Demo/res/index.html", type: "iframe" }])
	}

	// Открываем виджет "Медфлекс"
	function showMedflexSchedule() {
		let medflexRoundWidgetButton = document.querySelectorAll('.medflex-round-widget__button')[0]
		medflexRoundWidgetButton.click()
	}

	// Кнопки обратного звонка
	callbackButtons.forEach(button => button.addEventListener('click', event => {
		// console.log(event.target.innerText);
		showDefaultCallbackForm() // открываем дефолтную кнопку записи
	}));

	// Кнопки записи на прием
	appointmentButtons.forEach(button => button.addEventListener('click', event => {
		showDefaultCallbackForm() // открываем дефолтную кнопку записи
		// showMedflexSchedule() // открываем виджет Медфлекс
	}));
});