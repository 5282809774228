$(document).ready(function () {
	const myAccrodion = new HandyCollapse();
})

$(function () {
	$(".faq-card__item").on('click', function () {
		
		let posTop = $(this).position().top + 0 + 'px';

		console.log('posTop', posTop);

		$('.faq__arrow-down').animate({
			top: posTop
		}, 500);
	});

});