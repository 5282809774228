document.addEventListener('DOMContentLoaded', function () {
	let blogSwiper = new Swiper("#video-stream-swiper", {
		slidesPerView: "auto",
		// freeMode: true,
		// autoHeight: true,
		spaceBetween: 17,
		// centeredSlides: true,
		// paginationClickable: true,
		// loop: true,
		// slideToClickedSlide: true,

		pagination: {
			el: "#video-stream-swiper-pagination",
			clickable: true,
		},

		navigation: {
			nextEl: '#video-stream-swiper-button-next',
			prevEl: '#video-stream-swiper-button-prev',
		},

		// autoplay: {
		// 	delay: 5000,
		// 	speed: 800,
		// 	disableOnInteraction: false,
		// },

		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 1,
				slidesPerGroup: 1,
				spaceBetween: 10
			},
			// when window width is >= 480px
			480: {
				slidesPerView: "auto",
			},
		}
	});
})