document.addEventListener('DOMContentLoaded', function () {
	let aboutPhotogallerySwiper = new Swiper("#about-photogallery-swiper", {
		slidesPerView: 5,
		// autoHeight: true,
		spaceBetween: 25,
		centeredSlides: true,
		// paginationClickable: true,
		loop: true,
		// slideToClickedSlide: true,

		pagination: {
			el: "#about-photogallery-swiper-pagination",
			clickable: true,
		},

		navigation: {
			nextEl: '#about-photogallery-swiper-button-next',
			prevEl: '#about-photogallery-swiper-button-prev',
		},

		// autoplay: {
		// 	delay: 5000,
		// 	speed: 800,
		// 	disableOnInteraction: false,
		// },

		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 2,
				spaceBetween: 10
			},
			// when window width is >= 480px
			480: {
				slidesPerView: 3,
				slidesPerGroup: 3,
				spaceBetween: 30
			},
			// when window width is >= 640px
			640: {
				slidesPerView: 5,
				slidesPerGroup: 5,
				spaceBetween: 25
			}
		}
	});
})