document.addEventListener('DOMContentLoaded', function () {
	let swiper = new Swiper("#independent-rating-swiper", {
		slidesPerView: 3,
		autoHeight: true,
		spaceBetween: 30,
		centeredSlides: true,
		loop: true,
		// paginationClickable: true,
		// slideToClickedSlide: true,

		pagination: {
			el: "#independent-rating-swiper-pagination",
			clickable: true,
		},

		navigation: {
			nextEl: '#independent-rating-swiper-button-next',
			prevEl: '#independent-rating-swiper-button-prev',
		},

		// autoplay: {
		// 	delay: 5000,
		// 	speed: 800,
		// 	disableOnInteraction: false,
		// },

		// breakpoints: {
		// 	// when window width is >= 320px
		// 	320: {
		// 		slidesPerView: 2,
		// 		slidesPerGroup: 2,
		// 		spaceBetween: 20
		// 	},
		// 	// when window width is >= 480px
		// 	480: {
		// 		slidesPerView: 3,
		// 		slidesPerGroup: 3,
		// 		spaceBetween: 30
		// 	},
		// 	// when window width is >= 640px
		// 	640: {
		// 		slidesPerView: 4,
		// 		slidesPerGroup: 4,
		// 		spaceBetween: 40
		// 	}
		// }
	});
})