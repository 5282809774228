document.addEventListener('DOMContentLoaded', function () {
	let employeesSwiper = new Swiper("#employees-swiper", {
		slidesPerView: 3,
		// autoHeight: true,
		spaceBetween: 15,
		// centeredSlides: true,
		// paginationClickable: true,
		// loop: true,
		// slideToClickedSlide: true,
		pagination: {
			el: "#employees-swiper-pagination",
			clickable: true,
		},
		navigation: {
			nextEl: '#employees-swiper-button-next',
			prevEl: '#employees-swiper-button-prev',
		},
		// autoplay: {
		// 	delay: 5000,
		// 	speed: 800,
		// 	disableOnInteraction: false,

		// },

	});

	// new ShowMore('.specialist-index__grid', {
	// 	config: {
	// 		type: "list",
	// 		limit: 200,
	// 		btnClass: 'specialist-index__show-all',
	// 		more: "<a class='button button-outline specialist-index__show-all'>Показать все</a>",
	// 		less: "<a class='button button-outline specialist-index__show-all'>Скрыть</a>"
	// 	}
	// });
})