document.addEventListener('DOMContentLoaded', function () {
	let serviceSwiper = new Swiper("#service-swiper", {
		slidesPerView: "auto",
		// freeMode: true,
		// autoHeight: true,
		spaceBetween: 17,
		// centeredSlides: true,
		// paginationClickable: true,
		// loop: true,
		// slideToClickedSlide: true,
		pagination: {
			el: "#service-swiper-pagination",
			clickable: true,
		},
		navigation: {
			nextEl: '#service-swiper-button-next',
			prevEl: '#service-swiper-button-prev',
		},
		autoplay: {
			delay: 5000,
			speed: 800,
			disableOnInteraction: false,

		},
		breakpoints: {
			// when window width is >= 320px
			320: {
				slidesPerView: 2,
				slidesPerGroup: 2,
				spaceBetween: 20
			},
			// when window width is >= 480px
			480: {
				slidesPerView: "auto",
			},
		}
	});
})