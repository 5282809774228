window.onload = function () {
	lax.init()

	// Setup mouse move listener
	document.addEventListener('mousemove', function (e) {
		lax.__cursorX = e.clientX
		lax.__cursorY = e.clientY
	}, false)

	// Add lax driver for cursorX
	lax.addDriver('cursorX', function () {
		return lax.__cursorX || 0
	})

	// Add lax driver for cursorY
	lax.addDriver('cursorY', function () {
		return lax.__cursorY || 0
	})

	// Add lax driver for cursorXY
	lax.addDriver('cursorDistanceFromCenter', function () {
		var pageHeight = document.body.scrollHeight
		var pageWidth = document.body.scrollWidth

		var pageCenterX = pageWidth / 2
		var pageCenterY = pageHeight / 2

		var absDistanceFromCenterY = Math.abs((lax.__cursorY || 0) - pageCenterY) / pageCenterY
		var absDistanceFromCenterX = Math.abs((lax.__cursorX || 0) - pageCenterX) / pageCenterX

		return absDistanceFromCenterX + absDistanceFromCenterY
	})

	lax.addElements(".ceo-word__decor", {
		'cursorX': {
			"translateX": [
				[0, 'screenWidth'],
				['index * 100', '-100'],
			],
		},
		'cursorY': {
			"translateY": [
				[0, 'screenHeight'],
				['index * 50', '-100'],
			],
		},
		'cursorDistanceFromCenter': {
			"scale": [
				[0, 1],
				[1, '1 + (index * 0.05 )'],
			],
		}
	})

	// lax.addElements(".ceo-word__decor-wrapper", {
	// 	'cursorX': {
	// 		// "filter": [
	// 		// 	[0, 'screenWidth'],
	// 		// 	[0, 'screenWidth/2'],
	// 		// 	{
	// 		// 		"cssFn": (val) => {
	// 		// 			return `hue-rotate(${val % 360}deg)`
	// 		// 		}
	// 		// 	}
	// 		// ],
	// 	},
	// })
}