document.addEventListener(
	"DOMContentLoaded", () => {
		new Mmenu("#mobile-main-nav", {
			"offCanvas": {
				"position": "right",

				page: {
					// selector: "#page-wrapper"
					selector: "#scale-element"
				}
			},

			"theme": "light",
		});
	}
);